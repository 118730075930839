import styled from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { useIsTablet } from '../../utils/breakpoints';

import { ReactComponent as HamburgerIcon } from '../../svgs/icons/hamburger.svg';
import { ReactComponent as CloseIcon } from '../../svgs/icons/times.svg';
import { ReactComponent as ChevronLeftIcon } from '../../svgs/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '../../svgs/icons/chevron-right.svg';
import { ReactComponent as ArrowRightIcon } from '../../svgs/icons/arrow-right.svg';
import { ReactComponent as HostjaneLogo } from '../../svgs/icons/hostjane-logo.svg';
import { ReactComponent as DesktopLogo } from '../../svgs/icons/desktop-logo.svg';

import Icon from '../GenericIcon';
import mobileNavData from '../../../site-data/common/nav/mobile';
import { HostJaneLogoLink } from '../MainNavigation/common';

import Dropdown from './Dropdown';

const Banner = styled.div`
	height: 42px;
	z-index: 15;
	background-color: #e5eaef;
	box-shadow: 0 1px 2px #ccc;
	margin: -40px;
	margin-bottom: 40px;
	position: sticky;

	@media (max-width: 1024px) {
		background-color: #fff;
		box-shadow: 0 1px 2px #fff;
		height: 60px;
	}

	@media (max-width: 767px) {
		margin: 0px;
	}

	@media (max-width: 676px) {
		height: 45px;
		margin-top: -20px;
	}

	.hostjane-nav__menutoggle {
		cursor: pointer;
		height: 42px;
		width: 36px;
		background-color: transparent;
		border: none;
		padding: 0;
		text-align: left;
		color: #222;
		font-size: 14px;
		line-height: 1.4;

		svg {
			fill: currentColor;
			width: 22px;
			height: 100%;
			margin-top: 1px;
			color: #222;
			font-size: 14px;
			line-height: 1.4;
		}

		@media (max-width: 1024px) {
			width: auto;
			height: max-content;
			padding-right: 13px;

			svg {
				margin-top: 4px;
			}
		}

		@media (max-width: 677px) {
			padding-right: 9px;
		}
	}

	.banner-li_cta {
		display: flex;
		padding-left: 0;
		padding-right: 24px;
		margin: 0 !important;

		@media (max-width: 1024px) {
			padding: 0;
			align-items: center;
		}

		@media (max-width: 767px) {
			padding: 0px;
		}

		@media (max-width: 678px) {
			padding-top: 0px;
		}
		a {
			padding: 0 !important;
			display: block;
			margin-top: 11px;
			@media (max-width: 677px) {
				margin-top: 6px;
			}

			svg {
				width: 160px;
				height: auto;
				@media (max-width: 1024px) {
					width: 200px;
				}
				@media (max-width: 677px) {
					width: 170px;
					height: 20px;
				}
			}
		}
		.desktop-form-logo {
			display: block;
			@media (max-width: 1024px) {
				display: none;
			}
		}
		.mobile-form-logo {
			display: none;
			@media (max-width: 1024px) {
				display: block;
			}
		}
	}

	div {
		width: 100%;
		height: 100%;
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;

		@media (max-width: 1024px) {
			padding-left: 22px;
		}

		@media (max-width: 767px) {
			padding-left: 2px;
		}

		ul {
			width: 100%;
			height: 100%;
			display: inline-block;
			list-style: none;
			padding-left: 0;
			padding: 0;
			margin: 0;

			@media (max-width: 1024px) {
				display: flex;
				align-items: center;
			}

			li {
				list-style: none;
				height: 32px;
				margin-left: 2px;
				font-size: 15px;
				line-height: 38px;
				color: #303030;
				white-space: nowrap;
				position: relative;
				cursor: default;
				float: left;
				border: 1px solid transparent;
				border-radius: 3px 3px 0 0;
				@media (max-width: 1024px) {
					display: none;
					border: none;
					padding: 0;
					height: auto;
				}

				button {
					height: 42px;
					width: 34px;
					background-color: transparent;
					border: none;
					padding: 0;
					text-align: left;
					color: #222;
					font-size: 14px;
					line-height: 1.4;
					cursor: pointer;
					text-transform: none;
					margin: 0;
				}

				a {
					padding: 0 10px;
					font-weight: 600;
					color: #333;
					font-size: 16px;
					cursor: pointer;
					display: inline-block;
					position: relative;
					transition: color 0.2s ease-in;

					&:hover {
						color: #007dbc;
						text-decoration: none;
					}

					&.active {
						color: #007dbc;
						text-decoration: none;

						&:after {
							opacity: 1;
						}
					}

					&:after {
						opacity: 0;
						content: '';
						width: 45px;
						height: 2px;
						background-color: #007dbc;
						position: absolute;
						top: 100%;
						left: 50%;
						margin-top: 2px;
						transform: translateX(-50%);
						transition: opacity 0.2s ease-in;
					}
				}
			}

			@media (min-width: 1245px) {
				.item-dropdown {
					display: none;
				}
			}

			@media (max-width: 1245px) {
				.item-settings {
					display: none;
				}
			}

			@media (max-width: 1187px) {
				.item-profile {
					display: none;
				}
			}

			@media (max-width: 1095px) {
				.item-resolution {
					display: none;
				}
			}

			@media (max-width: 962px) {
				.item-reporting {
					display: none;
				}
			}
			@media (max-width: 890px) {
				.item-listings {
					display: none;
				}
			}

			@media (max-width: 815px) {
				.item-messages {
					display: none;
				}
			}

			@media (max-width: 1024px) {
				.item-order {
					display: none;
				}
			}

			@media (max-width: 1024px) {
				.item-dashboard,
				.item-hosting {
					display: none;
				}
			}
		}
	}
`;

const BannerFour = () => {
	const [open, setOpen] = useState(false);
	const isTablet = useIsTablet();

	useEffect(() => {
		if (typeof window !== 'undefined') {
			if (open && !isTablet) {
				document.body.classList.add('hostjane-mobile-menu-open');
			} else {
				document.body.classList.remove('hostjane-mobile-menu-open');
			}
		}
	}, [open, isTablet]);

	return (
		<>
			<Banner>
				<div>
					<ul>
						<li className="banner-li_cta">
							<button
								className="hostjane-nav__menutoggle"
								type="button"
								onClick={e => {
									e.preventDefault();
									setOpen(s => !s);
								}}
							>
								<HamburgerIcon
									role="presentation"
									alt="HostJane"
								/>
							</button>
							<a className="desktop-form-logo" href="/">
								<HostjaneLogo />
							</a>
							<a
								className="mobile-form-logo"
								href="https://www.hostjane.com/marketplace/categories"
							>
								<DesktopLogo />
							</a>
						</li>

						<li className="item-dashboard">
							<a href="https://www.hostjane.com/marketplace">
								Dashboard
							</a>
						</li>
						<li className="item-hosting">
							<a
								href="https://www.hostjane.com/marketplace"
								className="active"
							>
								Hosting
							</a>
						</li>
						<li className="item-order">
							<a href="https://www.hostjane.com/marketplace/orders">
								Orders
							</a>
						</li>
						<li className="item-messages">
							<a href="https://www.hostjane.com/marketplace/messages">
								Messages
							</a>
						</li>
						<li className="item-listings">
							<a href="https://www.hostjane.com/marketplace/listings">
								Listings
							</a>
						</li>
						<li className="item-reporting">
							<a href="https://www.hostjane.com/marketplace/reporting">
								Reporting
							</a>
						</li>
						<li className="item-resolution">
							<a href="https://www.hostjane.com/marketplace/disputes">
								Resolution
							</a>
						</li>
						<li className="item-profile">
							<a href="https://www.hostjane.com/marketplace/Swiftmodders">
								My Profile
							</a>
						</li>
						<li className="item-settings">
							<a href="https://www.hostjane.com/marketplace/settings">
								Settings
							</a>
						</li>
						<li className="item-dropdown">
							<Dropdown />
						</li>
					</ul>
				</div>
			</Banner>
			<CSSTransition
				unmountOnExit
				in={open}
				timeout={200}
				classNames="hostjane-mobile-popup"
			>
				<SmallNavigationPopup setOpen={setOpen} />
			</CSSTransition>
		</>
	);
};

export default BannerFour;

const SmallNavigationPopupContainer = styled.div`
	position: fixed;
	z-index: 10000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,.8);
	overflow: hidden;
	will-change: background-color;
	transition: background-color ${props => props.theme.siteApp.transition};

	.mobile-popup__close {
		cursor: pointer;               /* Change cursor to pointer */
		position: absolute;            /* Positioning for absolute placement */
		top: 10px;                     /* Adjust top positioning */
		border: none;                  /* No border */
		background-color: transparent; /* Transparent background */
		color: #cbcbcb;                   /* Text color for the "X" */
		width: 24px;
		height: 24px;
		line-height: 1;                /* Line height */
		text-shadow: 0 1px 2px rgba(255, 255, 255, 0.8); /* Optional shadow for contrast */
		margin-top: 3px; /* Adjust as needed */
		margin-left: 403px; /* Adjust as needed */
		transition: color 0.3s ease;  /* Smooth transition for hover effect */
	}

	/* Hover effect */
	.mobile-popup__close:hover svg {
    	fill: #818181; /* Change color of the SVG on hover */
	}

	/* Active state for button press effect */
	.mobile-popup__close:active {
		transform: scale(0.95);       /* Slightly shrink when pressed */
	}

	.hostjane-mobile-popup__inner {
		position: absolute;
		width: 400px;
		height: 100%;
		left: 0;
		overflow: hidden;
		background-color: #fff;
		transition: transform ${props => props.theme.siteApp.transition},
			opacity ${props => props.theme.siteApp.transition};
		padding: 0;
	}

	/** Transitions */
	&.hostjane-mobile-popup-enter {
		background-color: rgba(0, 0, 0, 0);
		.hostjane-mobile-popup__inner {
			transform: ${props =>
				props.isTablet ? 'translateX(-320px)' : 'translateY(100vh)'};
			opacity: 0;
		}
	}
	&.hostjane-mobile-popup-enter-active {
		background-color: rgba(0, 0, 0, 0.5);
		.hostjane-mobile-popup__inner {
			transform: ${props =>
				props.isTablet ? 'translateX(0)' : 'translateY(0)'};
			opacity: 1;
		}
	}
	&.hostjane-mobile-popup-exit {
		background-color: rgba(0, 0, 0, 0.5);
		.hostjane-mobile-popup__inner {
			transform: ${props =>
				props.isTablet ? 'translateX(0)' : 'translateY(0)'};
			opacity: 1;
		}
	}
	&.hostjane-mobile-popup-exit-active {
		background-color: rgba(0, 0, 0, 0);
		.hostjane-mobile-popup__inner {
			transform: ${props =>
				props.isTablet ? 'translateX(-320px)' : 'translateY(100vh)'};
			opacity: 0;
		}
	}

	/** Header */

	.hostjane-mobile-popup__header {
		display: flex;
		flex-flow: row nowrap;
		align-items: ${props => (props.isTablet ? 'flex-start' : 'center')};
		padding-left: 8px !important;
    	padding-top: 14px !important;
	}
	.hostjane-mobile-popup__logo {
		width: 225px;
		flex: 0 0 225px;
	}
	.hostjane-mobile-popup__close,
	.hostjane-mobile-popup__menunavback {
		width: 32px;
		height: 32px;
		flex: 0 0 32px;
		margin-left: auto;
		font-size: 24px;
		color: ${props => props.theme.siteApp.navSearch.color};
		transition: color ${props => props.theme.siteApp.transition};
		will-change: color;
		background: transparent;
		border: 0 none;
		outline: none;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		&:hover {
			color: ${props => props.theme.siteApp.primaryHoverColor};
		}
	}
	.hostjane-mobile-popup__menunavback {
		margin-left: 0;
		margin-right: ${props => props.theme.gutter.small / 2}px;
	}
	.hostjane-mobile-popup__browse {
		font-size: 18px;
		cursor: pointer;
		border-bottom: 2px dashed #222;
		text-transform: uppercase;
		color: #222;	
		text-decoration: none;
		font-weight: bold;
		letter-spacing: 1.5px;
	}
	.hostjane-mobile-popup__browse:hover {
		border-bottom: 2px solid #222;
	}

	.hostjane-mobile-popup__menunavback + .hostjane-mobile-popup__browse {
		margin-left: auto;
		margin-right: auto;
		+ .hostjane-mobile-popup__close {
			margin-left: 0;
		}
	}
	.hostjane-mobile-popup__tabletpager {
		margin: ${props => props.theme.gutter.small / 2}px 0;
		padding-top: 23px !important;
    	padding-left: 12px;
    	margin-bottom: 20px;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		height: 32px;
		.hostjane-mobile-popup__menunavback {
			margin-left: ${props => props.theme.gutter.small / -2}px;
		}
		.hostjane-mobile-popup__menunavback + .hostjane-mobile-popup__browse {
			margin-left: 0;
			margin-right: 0;
		}
	}

	/** Menu Items */
	.hostjane-mobile-popup__menu {
		position: relative;
		height: calc(
			100% - ${props => (props.isTablet ? '115px' : `${47 + 42}px`)}
		);
		overflow-y: auto;
	}
	.hostjane-mobile-popup__list {
		position: absolute;
		margin: 4px;
		padding-top: 10px;
		top: 0;
		left: 0;
		width: 100%;
		transition: transform ${props => props.theme.siteApp.transition},
			opacity ${props => props.theme.siteApp.transition};
		transform: translateX(0%);
		will-change: transform, opacity;

		display: flex;
		flex-flow: column nowrap;
		> * {
			flex: 0 0 auto;
		}

		&.hostjane-mobile-popup__list-enter {
			transform: translateX(
				${props => (props.goingBack ? '-100%' : '100%')}
			);
			z-index: 2;
			opacity: 0.5;
		}
		&.hostjane-mobile-popup__list-enter-active {
			transform: translateX(0%);
			z-index: 2;
			opacity: 1;
		}
		&.hostjane-mobile-popup__list-exit {
			transform: translateX(0%);
			z-index: 1;
			opacity: 1;
		}
		&.hostjane-mobile-popup__list-exit-active {
			z-index: 1;
			opacity: 0.5;
			transform: translateX(
				${props => (props.goingBack ? '100%' : '-100%')}
			);
		}
	}

	.hostjane-mobile-popup__listitem {
		display: flex;
		height: 44.8px;
		align-items: center;
		color: #111;
		cursor: pointer;
		background-color: transparent;
		border: 0 none;
		outline: none;
		padding: 8px;
		font-weight: 700;
		font-size: 18px;
		width: 100%;
		text-decoration: none;
		-webkit-tap-highlight-color: ${props =>
			props.theme.siteApp.tapBackground};

		&.hostjane-mobile-popup__listitem--hasicon {
			height: 40px;
			line-height: 1;
			&:last-of-type {
				margin-bottom: 30px;
			}
		}

		&.hostjane-mobile-popup__listitem--strong {
			font-weight: bold;
			text-decoration: none;
		}

		&:hover {
			color: ${props => props.theme.siteApp.primaryColor};
			text-decoration: none;
		}

		.hostjane-mobile-popup__listitemicon {
			height: 16px;
			width: 16px;
			margin: 0 10px;
			svg {
				height: 16px;
				width: 16px;
			}
		}

		&.hostjane-mobile-popup__listitem--hasBorderTop {
			margin-top: 20px;
			position: relative;
			&::before {
				content: '';
				display: block;
				height: 1px;
				background: #d5dbdb;
				position: absolute;
				left: 10px;
				right: 10px;
				top: -10px;
			}
		}

		&.hostjane-mobile-popup__listitem--footerButton {
			background-color: #f1f3f4;
			margin: auto 20px 20px;
			margin-top: 20px;
			height: 38px;
			width: auto;
			.hostjane-mobile-popup__listitemmore {
				height: 10px;
				width: 10px;
				margin-left: 6px;
				transition: transform 0.3s;
				display: flex;
				align-items: center;
				position: relative;
				top: 1px;
				color: #0064d2;
				svg {
					height: 10px;
					width: 10px;
					fill: currentColor;
				}
			}
			&:hover,
			&:active {
				color: ${props => props.theme.siteApp.navColor};
				.hostjane-mobile-popup__listitemmore {
					transform: translateX(6px);
				}
			}
		}

			&:hover,
			&:active {
				color: ${props => props.theme.siteApp.navColor};

				.hostjane-mobile-popup__listitemmore {
				transform: translateX(0.375rem); // Changed to rem units
				}
			}
		}
	}
	.hostjane-mobile-popup__listitemnext {
		font-size: 12px;
		margin-left: auto;
		margin-right: ${props => props.theme.gutter.small / 2}px;
	}
	.sidebar-head {
		cursor: pointer;
		display: flex;
		width: 100%;
		height: 44.8px;
		align-items: center;
		padding: 10px;
		background-color: #2b3642;
		transition: background-color 0.2s;
	}

	.sidebar-head:hover {
		background-color: #3d4d5e;
	}

	.sidebar-head a {
		display: flex;
		align-items: center;
		color: #fff;
		width: 100%;
		height: 100%;
		text-decoration: none;
	}

	.sidebar-head img {
		margin-right: 10px;
		border-radius: 50%;
		max-width: 30px;
		height: auto;
	}

	.sidebar-head p {
		font-size: 16px !important;
		font-weight: bold;
		margin: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	@media (max-width: 768px) {
		.hostjane-mobile-popup__inner {
		width: 100%;
		max-width: 100%;
		}

		.hostjane-mobile-popup__header {
		margin-top: 10px;
		margin-bottom: 10px;
		}

		.hostjane-mobile-popup__logo {
		width: 180px;
		flex: 0 0 180px;
		}

		.hostjane-mobile-popup__browse {
		font-size: 18px;
		}

		.hostjane-mobile-popup__listitem {
		font-size: 18px;
		padding: 10px;
		}

		.sidebar-head {
		height: 40px;
		}

		.sidebar-head p {
		font-size: 18px !important;
		}
	}

	@media (max-width: 480px) {
		.hostjane-mobile-popup__logo {
		width: 150px;
		flex: 0 0 150px;
		}

		.hostjane-mobile-popup__browse {
		font-size: 18px;
		}

		.hostjane-mobile-popup__listitem {
		font-size: 18px;
		}

		.sidebar-head {
		height: 36px;
		}

		.sidebar-head p {
		font-size: 18px !important;
		}
	}
	.mobile-popup__close--big-screen {
    display: none;
    
    @media (min-width: 1025px) {
      display: block;
    }
  }

  .mobile-popup__close--small-screen {
    display: block;
    
    @media (min-width: 1025px) {
      display: none;
    }
  }
`;

function SmallNavigationPopup({ className, setOpen }) {
	const [currentMenu, setCurrentMenu] = useState([]);
	const [goingBack, setGoingBack] = useState(false);
	const menuRef = useRef();
	const isTablet = useIsTablet();

	let menuToRender = mobileNavData;
	if (currentMenu.length) {
		// get the menu from the deep tree
		currentMenu.forEach(i => {
			menuToRender = menuToRender.navs[i];
		});
	}

	const closeButtonBigScreen = (
		<button
		  className="mobile-popup__close mobile-popup__close--big-screen"
		  type="button"
		  onClick={(e) => {
			e.preventDefault();
			setOpen(false);
		  }}
		>
		  <svg xmlns="http://www.w3.org/2000/svg" aria-label="Close menu" viewBox="0 0 24 24" fill="#FFF" width="24" height="24">
			<path d="M13.414,12l6.293-6.293a1,1,0,0,0-1.414-1.414L12,10.586,5.707,4.293A1,1,0,0,0,4.293,5.707L10.586,12,4.293,18.293a1,1,0,1,0,1.414,1.414L12,13.414l6.293,6.293a1,1,0,0,0,1.414-1.414Z"></path>
		  </svg>
		</button>
	  );
	
	  const closeButtonSmallScreen = (
		<button
		  className="hostjane-mobile-popup__close mobile-popup__close--small-screen"
		  type="button"
		  onClick={(e) => {
			e.preventDefault();
			setOpen(false);
		  }}
		>
		  <Icon title="Close menu">
			<CloseIcon />
		  </Icon>
		</button>
	  );

	// create the back button
	const backButton = (
		<button
			className="hostjane-mobile-popup__menunavback"
			type="button"
			onClick={e => {
				e.preventDefault();
				setGoingBack(true);
				const newCurrentMenu = [...currentMenu];
				newCurrentMenu.pop();
				setCurrentMenu(newCurrentMenu);
			}}
		>
			<Icon title="Go back">
				<ChevronLeftIcon />
			</Icon>
		</button>
	);

	const currentMenuTitle = (
		<div className="hostjane-mobile-popup__browse">
			{menuToRender.title}
		</div>
	);

	const listKey = currentMenu.length ? currentMenu.join('-') : 'root';

	useEffect(() => {
		return () => {
			document.body.classList.remove('hostjane-mobile-menu-open');
		};
	}, []);

	return (
		<SmallNavigationPopupContainer
			className={className}
			isTablet={isTablet}
			goingBack={goingBack}
		>
			{closeButtonBigScreen}
			<div className="hostjane-mobile-popup__inner">
			<div class="sidebar-head">
    <a href="https://www.hostjane.com/marketplace/login" class="desktop-view text-white p-2 d-flex align-items-center">
        <img src="https://www.hostjane.com/marketplace/assets/images/empty-profile.svg" width="27" height="27" class="rounded-circle mr-2"></img>
        <p class="m-0 f-18">Hello, sign in</p>
    </a>
</div>
				<div className="hostjane-mobile-popup__header">
					{isTablet ? (
						<HostJaneLogoLink
							to="https://www.hostjane.com"
							className="hostjane-mobile-popup__logo"
							width={225}
						/>
					) : (
						<>
							{currentMenu.length ? backButton : null}
							{currentMenuTitle}
						</>
					)}
					 {closeButtonSmallScreen}
				</div>

				{isTablet ? (
					<div className="hostjane-mobile-popup__tabletpager">
						{currentMenu.length ? backButton : null}
						{currentMenuTitle}
					</div>
				) : null}

				<div className="hostjane-mobile-popup__menu" ref={menuRef}>
					<TransitionGroup component={null}>
						<CSSTransition
							key={listKey}
							classNames="hostjane-mobile-popup__list"
							timeout={200}
							unmountOnExit
							onEnter={() => {
								if (menuRef.current) {
									menuRef.current.scrollTop = 0;
								}
							}}
							onExited={() => {
								setGoingBack(false);
							}}
						>
							<div className="hostjane-mobile-popup__list">
								{/* <div className="hostjane-mobile-popup__menuinner"> */}
								{menuToRender.navs.map((nav, index) => {
									const hasSubtree = nav.navs !== undefined;
									const key = `${listKey}-${index}`;

									// If this is a subtree, then use a button
									if (hasSubtree) {
										return (
											<button
												key={key}
												type="button"
												className="hostjane-mobile-popup__listitem"
												onClick={e => {
													e.preventDefault();
													const newCurrentMenu = [
														...currentMenu,
														index,
													];
													setCurrentMenu(
														newCurrentMenu
													);
												}}
											>
												<span className="hostjane-mobile-popup__listitemtitle">
													{nav.title}
												</span>
												<Icon className="hostjane-mobile-popup__listitemnext">
													<ChevronRightIcon />
												</Icon>
											</button>
										);
									}

									const navClassName = classNames(
										'hostjane-mobile-popup__listitem',
										`hostjane-mobile-popup__listitem--${
											nav.type || 'regular'
										}`,
										{
											'hostjane-mobile-popup__listitem--strong':
												nav.strong,
											'hostjane-mobile-popup__listitem--hasicon':
												nav.icon,
										}
									);

									const navChild = (
										<>
											{nav.icon ? (
												<span className="hostjane-mobile-popup__listitemicon">
													<nav.icon />
												</span>
											) : null}
											<span className="hostjane-mobile-popup__listitemtitle">
												{nav.title}
											</span>
											{nav.type === 'footerButton' ? (
												<span className="hostjane-mobile-popup__listitemmore">
													<ArrowRightIcon />
												</span>
											) : null}
										</>
									);

									// else use Link to a, based on whether this is internal or not
									if (nav.internal) {
										return (
											<Link
												key={key}
												to={nav.link}
												className={navClassName}
												onClick={() => {
													document.body.classList.remove(
														'hostjane-mobile-menu-open'
													);
												}}
											>
												{navChild}
											</Link>
										);
									}

									return (
										<a
											key={key}
											href={nav.link}
											className={navClassName}
										>
											{navChild}
										</a>
									);
								})}
								{/* </div> */}
							</div>
						</CSSTransition>
					</TransitionGroup>
				</div>
			</div>
		</SmallNavigationPopupContainer>
	);
}
